import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { ContactForm } from '../forms'
import 'font-awesome/css/font-awesome.min.css'
import { Link } from 'gatsby'

const ContactPageTemplate = ({ title, subtitle, meta_title, meta_description }) => {
  return <div>
      <Helmet>
        <title>{meta_title}</title>
        <meta name='description' content={meta_description}/>
      </Helmet>
      <div className='col-md-10 col-sm-8 col-xs-10 offset-sm-1'>
        <div className="contacts contacts-page-map-overlay maxwidth-theme">
          <div className="contacts-wrapper">
            <div className="section1">
              <h1 className="title">
                Комерційні і технічні консультації на території України
              </h1>
              <h2 className='subtitle'>
                Телефон:           <b><a href="tel:+380989258088">+38 098 9258088</a></b><br/>
                E-mail:            <b><a
                href="mailto:krantechnik.ua@gmail.com">krantechnik.ua@gmail.com</a></b>
              </h2>
            </div>
            <div className='section'>
            <div className="row">
              <div className="col-md-6">
                <a href="https://www.swfkrantechnik.com/en/" target="_blank">
                  <img  width={120} alt='' src='/img/swf.png'  />
                </a>

                <h1 className='title'>SWF Krantechnik GmbH </h1>
                <div>
  <pre className="address">
    Почтовый адрес:    <b>Boehringerstr. 4,</b><br/>
    <b>                   D-68307 Mannheim,</b><br/>
    <b>                   Germany</b><br/>
              Телефон:           <b>+49 621 78-99-00</b><br/>
    Сайт:        <b><a
    href="https://www.swfkrantechnik.com/en/" target="_blank">      www.swfkrantechnik.com</a></b><br/>
    E-mail:            <b><a
    href="mailto:aleksandar.raketic@swfkrantechnik.com">aleksandar.raketic@swfkrantechnik.com</a></b></pre>
                </div>
              </div>


              <div className="col-md-6">

                <a href="http://ru.surgentas.lt/" target="_blank">
                <img  width={250}  alt='' src='/img/Surgentas_Logotipe.png'  />
                </a>

                <h1 className='title'>UAB Surgentas </h1>
                <div>
  <pre className="address">
    Почтовый адрес:    <b>Saltoniškių g. 31,</b><br/>
                         <b>                   08105 Vilnius,</b><br/>
                          <b>                   Lithuania</b><br/>
              Телефон:           <b>+370 618-033-11</b><br/>
    Сайт:        <b><a
    href="http://ru.surgentas.lt/" target="_blank">      www.surgentas.lt</a></b><br/>
    E-mail:            <b><a
    href="mailto:info@surgentas.lt">info@surgentas.lt</a></b></pre>
                </div>
              </div>
              </div>
            </div>

            <div className='section'>
              <div className="row">
                <div className="col-md-6">
                 <h1 className='title'>
                    {title}
                  </h1>
                  <h2 className='subtitle'>
                    {subtitle}
                  </h2>
                  {/*</div>*/}
                </div>
                <div className="col-md-6">
                  <div className='container'>
                    <ContactForm/>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

}
ContactPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
}

export default ContactPageTemplate
