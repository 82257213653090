import React from 'react'
import { Formik, Field } from 'formik'
import { navigate } from 'gatsby-link'
import validationSchema from './validationSchema'

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}
{/*при разработке добавить http://krantechnik*/}
const ContactForm = () => {
  return (

    <Formik
      initialValues={{ name: '', email: '', message: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        fetch("/api/mail.php", {                                 //eslint-disable-line
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: encode({
            'form-name': 'contact',
            ...values,
          }),
        })
          .then(() => {
            // ( document.getElementsByClassName('navbar').after('<div class="alert alert-success alert-dismissible">' + 'Success: You have added' + '<button type="button" class="close" data-dismiss="alert">&times;</button></div>'))
            let div = document.createElement('div');
            div.className = "mailalert";
            div.innerHTML = "Сообщение отправлено!";
            document.getElementsByClassName('navbar')[0].after(div)
            // document.body.append(div);
            // navigate('/contact/success')
            setSubmitting(false)
          })
          .catch(error => {
            console.log(error)
            alert("Ошибка! Попробуйте снова");                            //eslint-disable-line
            setSubmitting(false)
          })
      }}
      render={({
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        handleReset,
      }) => (<form
        name='contact'
        onSubmit={handleSubmit}
        onReset={handleReset}
        data-netlify='true'
        data-netlify-honeypot='bot-field'
      >
        <div className="row">
          <div className="col-md-4">
        <div className='field'>
          <label className='label'>Iм'я</label>
          <div className='control'>
            <Field className='input' type='text' placeholder="Введіть ім'я" name='name' id='name' />
          </div>
          {touched.name && errors.name && <small className='has-text-danger'>{errors.name}</small>}
        </div>

        <div className='field'>
          <label className='label'>Email</label>
          <div className='control'>
            <Field className='input' type='email' placeholder='Email' name='email' id='email' />
          </div>
          {touched.email && errors.email && <small className='has-text-danger'>{errors.email}</small>}
        </div>
          </div>
          <div className="col-md-8">
        <div className='field'>
          <label className='label'>Повідомлення</label>
          <div className='control'>
            <Field className='textarea' component='textarea' placeholder='Повідомлення' name='message' id='message' rows='6' />
          </div>
          {touched.message && errors.message && <small className='has-text-danger'>{errors.message}</small>}
        </div>


        <div className='field is-grouped is-pulled-right'>
          <div className='control'>
            <button className='button is-primary is-outlined' type='reset'>Очистити</button>
          </div>
          <div className='control'>
            <button className='button is-primary is-outlined' type='submit' disabled={isSubmitting}>Надіслати</button>
          </div>
        </div>
          </div>
        </div>
      </form>)}
    />
  )
}

export { ContactForm }
